import {
  ImageResponse,
  HotelTile,
  Testimonial,
  Place,
  Review,
  TripAdvisorSubRating,
  ClimateMonths
} from '@model/iceberg';
import { Room } from '@model/iceberg/deal-finder/deal-finder';
import { Country, Region, Resort, Hotel, TripTypes, CoOrdinates } from '@model/common';
import { TourDetails, TourDestinationDetails } from '@model/tours';
import { PageTemplates, ContentTripType, ContentDestination, TravelGuide } from './common';

export interface PageData {
  pageType: PageTemplates;
  name: string;
  path: string;
  tripType: TripTypes;
}

export interface PageConfig {
  type: string;
  name: PageTemplates;
  components: Array<TemplateComponent>;
}

export interface TemplateData {
  components: Array<TemplateComponent>;
}

export type TemplateComponent =
  | BaseComponent
  | GenericComponent
  | BenefitGroupComponent
  | DestinationGroupComponent
  | HeroComponent
  | BreadrumbComponent
  | HotelTileGroupComponent
  | SeparatorComponent
  | TestimonialComponent
  | TripTypesComponent;

export interface BaseComponent {
  handle: string;
  path: string;
  tripType?: TripTypes;
  separatorAbove?: string;
  separatorBelow?: string;
}

// Extensible Component types

export interface GenericComponent extends BaseComponent {
  value: string;
}

export interface TitleComponent extends BaseComponent {
  title: string;
  subTitle?: string | null;
}

export interface DescriptionComponent extends GenericComponent {
  showReadMore?: boolean;
}

export interface SeoComponent extends TitleComponent {
  description: string;
}

export interface CtaComponent {
  ctaTitle: string | null;
  ctaUrl?: string;
  hideCta: boolean;
}

// Components

export interface AccessibilityInformationComponent extends BaseComponent {
  isAccessible: boolean;
}

export enum BenefitGroupType {
  DEFAULT = 'default',
  TRUST_BAR = 'trustBar'
}

export interface BenefitGroupComponent extends TitleComponent {
  type: BenefitGroupType;
  benefits: Array<ContentBenefit>;
}

export interface BreadrumbComponent extends BaseComponent {
  geography: Array<Country | Region | Resort | Hotel>;
}

export interface ClimateComponent extends BaseComponent {
  temperatures: ClimateMonths;
  rainfall: ClimateMonths;
  sunshine: ClimateMonths;
  sunrise: null;
  description: string;
}

export interface ContentBenefit extends TitleComponent, CtaComponent {
  details: string;
  icon: string;
}

export interface DestinationGroupComponent extends TitleComponent, CtaComponent {
  tripTypeOverride: string | null;
  destinations: Array<ContentDestination>;
}

export interface FacilitiesComponent extends BaseComponent {
  facilities: Array<string>;
  facilityNotes: Array<string>;
}

export interface HeroComponent extends BaseComponent {
  imageSets: Array<ImageResponse>;
}

export interface HeroImageComponent extends BaseComponent, ImageResponse {
  type: string;
  title?: string;
}

export interface HotelTileGroupComponent extends TitleComponent, Pick<CtaComponent, 'ctaTitle'> {
  tripTypeOverride: string | null;
  tiles: Array<HotelTile>;
  ctaPath: string;
}

export interface LocalAreaComponent extends BaseComponent {
  location: string;
  place: Place;
  coordinates: CoOrdinates;
}

export interface PriceCalendarComponent extends BaseComponent {}

export interface ProductMetaDataComponent extends BaseComponent {
  continent: string;
  country: string;
  region: string;
  resort: string;
  hotelRating: string;
  tripAdvisorRating: string;
}

export interface ProductSummaryComponent extends BaseComponent {
  summary: string;
}

export interface ProductTitleComponent extends TitleComponent {}

export interface RoomTypesComponent extends BaseComponent {
  rooms: Array<Room>;
}

export interface SeparatorComponent extends BaseComponent {
  height: number;
}

export interface SpecialOffersComponent extends BaseComponent {
  offers: Array<string>;
}

export interface TestimonialComponent extends BaseComponent {
  testimonials: Array<Testimonial>;
  imageSets: Array<ImageResponse>;
}

export interface TourGroupComponent extends TitleComponent, CtaComponent {
  packages: Array<TourDetails>;
}

export interface TourDestinationComponent extends TitleComponent, CtaComponent {
  packages: Array<TourDestinationDetails>;
}

export interface TravelGuideComponent extends BaseComponent {
  guides: Array<TravelGuide>;
}

export interface TripAdvisorReviewsComponent extends BaseComponent {
  review: Array<Review>;
  tripAdvisorRating: string;
  tripAdvisorSubRating: TripAdvisorSubRating;
}

export interface TripTypesComponent extends BaseComponent {
  tripTypes: Array<ContentTripType>;
}
